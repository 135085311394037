import React, { useState } from 'react'
import {navigate} from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Link, FormattedMessage } from "gatsby-plugin-intl"

export default class MailChimpForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            name: null,
            email: null,
            company: null
        }
    }

    _handleChange = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    _handleSubmit = (e) => {
        e.preventDefault();

        addToMailchimp(this.state.email, {NAME: this.state.name, COMPANY: this.state.company})
            .then(({ msg, result }) => {

                if (result !== 'success') {
                    throw msg;
                }
                //alert(msg);
                navigate('/success');
            })
            .catch((err) => {
                console.log('err', err);
                alert(err);
            });
    }


    render () {
        return (
            <div className="col-md-8 mt-5">
                <div className="form-container">
                    <form onSubmit={e => {this._handleSubmit(e)}} className="mailchimp-form">

                    <div className="form-group">
                        <label htmlFor="email"><FormattedMessage id="Email" /></label>
                        <input required type="email" onChange={this._handleChange} name="email" id="email" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name"><FormattedMessage id="Name" /></label>
                        <input type="text" onChange={this._handleChange} name="name" id="name" className="form-control" />
                    </div>
                    <div className="row">
                        <div className="form-group col-md-8">
                            <div className="custom-control custom-checkbox">
                                <input
                                    required
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="aszfcheck"
                                    value="on"
                                    name="aszfcheck"
                                    onChange={this._handleChange}
                                />
                                <label className="custom-control-label" htmlFor="aszfcheck">
                                    <FormattedMessage id="Accept_the" />
                                    <Link to="/terms/adatvedelem"><FormattedMessage id="T&C_link" /></Link>
                                </label>
                            </div>
                        </div>
                    </div>
                    <button className="button float-right w-100" type="submit"><FormattedMessage id="Submit" /></button>
                    </form>
                </div>
            
            </div>
        )
    } 
}
