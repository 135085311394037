import React from "react";
import { graphql, withPrefix } from "gatsby";
import SEO from "../components/SEO";
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../layouts/index";

import MailChimpForm from "../components/MailChimpForm";
import BackgroundImage from "gatsby-background-image";

const Study = (props) => {
	const { title, description } = props.data.markdownRemark.frontmatter;
	const { html } = props.data.markdownRemark;
	let featuredImgFluid =
		props.data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid;
	let studyImageData =
		props.data.markdownRemark.frontmatter.studyImage.childImageSharp.gatsbyImageData;
	return (
		<Layout bodyClass='page-study' path={props.location.pathname}>
			<SEO
				title={title}
				description={description}
				image={props.data.markdownRemark.frontmatter.studyImage.publicURL}
			/>
			<BackgroundImage fluid={featuredImgFluid} className='study-wrapper'>
				<div className='filter'></div>
				<BackgroundImage fluid={featuredImgFluid} className='study-small-img'>
					&nbsp;
				</BackgroundImage>
				<div className='container study-content'>
					<div className='row intro'>
						<div className='col-md-8'>
							<div className='study study-single'>
								<h1 className='title mb-3'>{title}</h1>
								<div
									className='content'
									dangerouslySetInnerHTML={{ __html: html }}
								/>
							</div>
						</div>
						<div className='col-md-4 px-5 px-md-0'>
							<GatsbyImage
								imgStyle={{ objectFit: "contain" }}
								fluid={studyImageData}
								className='study-im'
							/>
						</div>
						<MailChimpForm />
					</div>
				</div>
			</BackgroundImage>
		</Layout>
	);
};

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        lang
        description
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        studyImage {
          publicURL
          childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
        }
      }
      html
    }
  }
`;

export default Study;
